<template>
  <div class="main-content">
    <notifications v-if="isAuthenticated"></notifications>
    <side-bar v-if="isAuthenticated">
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Home',
            icon: 'fas fa-home text-green',
            path: '/home',
          }">
        </sidebar-item>
        <sidebar-item
          v-if="
            permissions.includes('CanViewAllCustomers') ||
            permissions.includes('CanViewOwnCustomers') ||
            permissions.includes('CanViewContact') ||
            permissions.includes('CanViewSalesCall') ||
            permissions.includes('CanViewSalesInvoices')
          "
          :link="{
            name: 'Customers',
            icon: 'fas fa-buildings text-info',
            path: '/customers',
          }">
          <sidebar-item
            v-if="permissions.includes('CanViewAllCustomers') || permissions.includes('CanViewOwnCustomers')"
            :link="{ name: 'Companies', path: '/customers/companies' }" />
          <sidebar-item
            v-if="permissions.includes('CanViewContact')"
            :link="{ name: 'Contacts', path: '/customers/contacts' }" />
          <sidebar-item
            v-if="permissions.includes('CanViewSalesCall')"
            :link="{ name: 'Sales Calls', path: '/customers/sales-calls' }" />
          <sidebar-item
            v-if="permissions.includes('CanViewSalesInvoices')"
            :link="{
              name: 'Sales Invoices',
              path: '/invoices',
            }">
          </sidebar-item>
          <sidebar-item :link="{ name: 'Customer Types', path: '/admin/customer-types' }" />
        </sidebar-item>
        <sidebar-item
          v-if="permissions.includes('CanViewProducts')"
          :link="{
            name: 'Products',
            icon: 'fas fa-warehouse-full text-danger-tint-5',
            path: '/products',
          }">
        </sidebar-item>
        <sidebar-item
          v-if="permissions.includes('CanViewLogistics')"
          :link="{
            name: 'Logistics',
            icon: 'fas fa-truck-fast text-info-tint-5',
            path: '/logistics',
          }">
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Amazon',
            icon: 'fab fa-amazon text-info-tint-5',
            path: '/amazon/orders',
          }">
          <sidebar-item
            :link="{ name: 'Orders', path: '/amazon/orders' }" />
          <sidebar-item
            :link="{ name: 'Refunds', path: '/amazon/refunds' }" />
          <sidebar-item
            :link="{ name: 'Transactions', path: '/amazon/transactions' }" />
        </sidebar-item>
        <sidebar-item
          v-if="

            permissions.includes('CanViewServiceRequests') ||
            permissions.includes('CanViewShippingRecords') ||
            permissions.includes('CanViewReceivingRecords')
          "
          :link="{
            name: 'Service',
            icon: 'fas fa-hammer text-warning-tint-4',
            path: '/service',
          }">
          <sidebar-item
            v-if="permissions.includes('CanViewServiceRequests')"
            :link="{ name: 'Service Requests', path: '/service/request-home' }" />
          <sidebar-item
            v-if="permissions.includes('CanViewShippingRecords')"
            :link="{ name: 'Shipping', path: '/service/shipping' }" />
          <sidebar-item
            v-if="permissions.includes('CanViewReceivingRecords')"
            :link="{ name: 'Receiving', path: '/service/receiving' }" />
        </sidebar-item>
        <sidebar-item
          v-if="permissions.includes('CanViewDevices') || permissions.includes('CanViewDeviceLocations')"
          :link="{
            name: 'Equipment',
            icon: 'fas fa-pump text-orange',
            path: '/pumps',
          }">
          <sidebar-item
            v-if="permissions.includes('CanViewDevices')"
            :link="{ name: 'Device', path: '/pumps/home' }" />
          <sidebar-item
            v-if="permissions.includes('CanViewDeviceLocations')"
            :link="{ name: 'Device Location', path: '/pumps/depots' }" />
        </sidebar-item>
        <sidebar-item
          v-if="permissions.includes('CanViewSalesCommissions')"
          :link="{
            name: 'Targets & Commissions',
            icon: 'fas fa-dollar text-green',
            path: '/commissions',
          }">
          <sidebar-item
            v-if="roles.includes('ADMIN')"
            :link="{ name: 'Sales Targets', path: '/commissions/targets' }" />
          <sidebar-item
            v-if="permissions.includes('CanViewSalesCommissions')"
            :link="{ name: 'Commission Reports', path: '/commissions/reports' }" />
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Reports',
            icon: 'fas fa-file text-indigo',
            path: '/reports',
          }">
          <sidebar-item
            v-if="roles.includes('SLS_REP') || roles.includes('ADMIN') || roles.includes('SLS_MGR')"
            :link="{ name: 'Customer Weekly Sales', path: '/reports/customer-sales-history' }" />
          <sidebar-item
            v-if="roles.includes('SLS_REP') || roles.includes('ADMIN') || roles.includes('SLS_MGR')"
            :link="{ name: 'Customer Sales Detail', path: '/reports/customer-sales-detail' }" />
          <sidebar-item
            v-if="roles.includes('SLS_REP') || roles.includes('ADMIN') || roles.includes('SLS_MGR')"
            :link="{ name: 'Supplier Sales', path: '/reports/supplier-sales-history' }" />
          <sidebar-item
            v-if="roles.includes('SLS_REP') || roles.includes('ADMIN') || roles.includes('SLS_MGR')"
            :link="{ name: 'Customer by Territory', path: '/reports/customer-territory-list' }" />
          <sidebar-item
            v-if="
              roles.includes('ADMIN') ||
              roles.includes('CUST_SRV') ||
              roles.includes('OFF_MGR') ||
              roles.includes('WAREHOUSE')
            "
            :link="{ name: 'Service Request History', path: '/reports/service-request-history' }" />
          <sidebar-item
            v-if="roles.includes('ADMIN') || roles.includes('CUST_SRV') || roles.includes('OFF_MGR')"
            :link="{ name: 'Rental Pump Invoicing', path: '/reports/rental-pump-report' }" />
          <sidebar-item
            v-if="roles.includes('SLS_REP') || roles.includes('ADMIN') || roles.includes('SLS_MGR')"
            :link="{ name: 'Sales Performance', path: '/reports/sales-performance-report' }" />
          <sidebar-item
            v-if="
              roles.includes('ADMIN') ||
              roles.includes('CUST_SRV') ||
              roles.includes('OFF_MGR') ||
              roles.includes('WAREHOUSE')
            "
            :link="{ name: 'Depot Inventory Report', path: '/reports/depot-inventory-report' }" />
        </sidebar-item>
        <sidebar-item
          v-if="userIsAdmin"
          :link="{
            name: 'Admin',
            icon: 'fas fa-gears text-grey',
            path: '/admin',
          }">
          <sidebar-item :link="{ name: 'Users', path: '/admin/users' }" />
          <sidebar-item :link="{ name: 'User Roles', path: '/admin/roles' }" />
          <sidebar-item :link="{ name: 'Territories', path: '/admin/territories' }" />
          <sidebar-item :link="{ name: 'Suppliers', path: '/admin/suppliers' }" />
          <sidebar-item
            :link="{
              name: 'Sales Call Types',
              path: '/admin/sales-call-types',
            }" />
          <sidebar-item :link="{ name: 'Price Lists', path: '/admin/price-lists' }" />
          <sidebar-item :link="{ name: 'Pump Types', path: '/admin/pump-types' }" />
        </sidebar-item>
        <sidebar-item
          v-if="userIsAdmin"
          :link="{
            name: 'Release Notes',
            icon: 'fas fa-book text-light',
            path: '/release-notes',
          }">
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-content">
      <navbar
        v-if="isAuthenticated"
        :type="$route.meta.navbarType"></navbar>
      <div v-if="showLoader">
        <m-loader />
      </div>
      <div @click="$sidebar.displaySidebar(false)">
        <div
          id="root"
          class="container-fluid">
          <router-view></router-view>
        </div>
      </div>

      <b-modal
        id="notification-modal"
        v-model="notificationModalState"
        class="notification-modal"
        size="lg"
        scrollable
        header-bg-variant="warning"
        centered
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close>
        <template #modal-header>
          <b-row class="align-items-center text-white">
            <b-colxx
              md="10"
              sm="10">
              <span class="h3 text-white">Notifications</span>
            </b-colxx>

            <b-colxx
              md="2"
              sm="2"
              class="pull-right">
              <span
                class="i fas fa-circle-xmark text-white icon-pointer"
                @click="toggleNotificationModal()" />
            </b-colxx>
          </b-row>
        </template>
        <template>
          <div class="p-0">
            <div
              v-for="(notify_item, index) in notificationList"
              :key="index">
              <m-notification-item
                :item="notify_item"
                @read-notification-item="readNotificationItem($event)"
                @delete-item="deleteNotificationItem($event)" />
            </div>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex"
  import Navbar from "@/views/dashboard/Navbar.vue"
  import MLoader from "@/components/loader/MLoader.vue"
  import MNotificationItem from "./components/common/MNotificationItem.vue"

  export default {
    name: "App",

    components: {
      Navbar,
      MLoader,
      MNotificationItem,
    },

    data() {
      return {}
    },

    computed: {
      ...mapGetters("auth", ["isAuthenticated", "user", "roles", "permissions"]),
      ...mapGetters("common", ["showLoader", "notificationModalState"]),
      ...mapGetters("notifications", ["notificationList"]),

      userIsAdmin() {
        if (this.roles.includes("ADMIN")) {
          return true
        } else {
          return false
        }
      },
    },

    created() {},

    mounted() {},

    methods: {
      toggleNotificationModal() {
        this.$store.dispatch("common/toggleNotificationModal")
      },

      readNotificationItem(item) {
        item.message_read_yn = !item.message_read_yn
        this.$store.dispatch("notifications/updateNotification", item)
      },

      deleteNotificationItem(item) {
        this.$store.dispatch("notifications/deleteNotification", item)
      },
    },
  }
</script>

<style lang="scss" scoped>
  /deep/ #notification-modal {
    display: block;
    width: 30%;
    height: 100%;
    left: 70%;

    @media only screen and (max-width: 720px) {
      width: 95%;
      left: 5%;
    }

    & .modal-body {
      padding: 0.5rem 0.5rem;
    }
  }

  /deep/ .modal-dialog-centered.modal-dialog-scrollable .modal-content {
    height: 100%;
  }

  .slide-right {
    position: st;
    left: 40px;
  }
</style>
